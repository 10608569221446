const UserSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'My Items',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.items'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-settings-line'
  },
  {
    title: 'My Peripherals',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.item-details'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-settings-2-line'
  },
  {
    title: 'My Borrowing',
    name: 'sidebar.user.borrowing',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: 'pl-2',
    is_icon_class: true,
    icon: 'ri-folder-user-line',
    children: [
      {
        title: 'Borrowings',
        is_active: false,
        link: {
          name: 'user.borrowings'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Histories',
        is_active: false,
        link: {
          name: 'user.borrowing-histories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      }
    ]
  }
]

export default UserSideMenu
