<template>
  <div class="iq-sidebar">
    <div class="iq-sidebar-logo d-flex flex-row justify-content-between pt-2 pb-0 px-0">
      <!-- <div
        class="iq-light-logo flex-grow-1 align-self-center text-md-right"
      >
        <img
          src="@/assets/images/aljay-logo.png"
          class="img-fluid"
          alt="logo"
        >
      </div> -->
      <div class="d-md-none d-lg-none d-block iq-menu-bt-sidebar align-self-center">
        <div class="iq-menu-bt align-self-center">
          <div
            class="wrapper-menu"
            @click="sidebarMini"
          >
            <div class="main-circle">
              <i class="ri-arrow-left-s-line" /></div>
            <div class="hover-circle">
              <i class="ri-arrow-right-s-line" /></div>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <CollapseMenu
          :items="menuItems"
          :open="true"
          :sidebar-group-title="true"
        />
      </nav>
      <div class="p-3" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { core, APPNAME } from '@/config/pluginInit'
import CollapseMenu from '@/components/core/menus/CollapseMenu'
import { AdminSideMenu, UserSideMenu } from '@/navigation'

export default {
  name: 'SidebarStyle',
  components: {
    CollapseMenu
  },

  data () {
    return {
      appName: APPNAME
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    menuItems () {
      const user = this.$store.getters['auth/user']

      switch (user?.role) {
        case 'admin': {
          return AdminSideMenu
        }
        case 'user': {
          return UserSideMenu
        }
        default: {
          return []
        }
      }
    }
  },

  mounted () {
    core.SmoothScrollbar()
  },

  methods: {
    sidebarMini () {
      core.triggerSet()
    }
  }
}
</script>
