<template>
  <div>
    <div class="wrapper">
      <!-- <Loader /> -->
      <SidebarStyle />
      <HeaderStyle />
      <div
        id="content-page"
        class="content-page"
      >
        <transition
          name="router-anim"
          enter-active-class="animated fadeInUp"
          mode="out-in"
          leave-active-class="animated fadeOut"
        >
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderStyle from '@/components/core/partials/HeaderStyle/HeaderStyle.vue'
import SidebarStyle from '@/components/core/partials/SidebarStyle/SidebarStyle.vue'
// import LayoutFooter from '@/layouts/Components/LayoutFooter.vue'
// import Loader from '@/components/core/loader/Loader'

export default {
  name: 'Layout2',
  components: {
    // Loader,
    HeaderStyle,
    SidebarStyle
    // LayoutFooter
  },
  mounted () {
    document.body.classList = ''
  },
  destroyed () {
    document.body.classList = ''
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
